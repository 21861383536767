
.linechart {
    background-color:transparent;
    font-family:Arial,sans-serif;
    font-size:12px;
    width:100%;
    height:100%;
}

.linechart path {
    stroke-width: 1.8;
    stroke:var(--c4);
    fill:none;
}
.linechart line {
    shape-rendering: crispEdges;
    stroke: var(--c6);
    opacity:0.5;
}

.linechart text {
    fill: var(--c6);
}

.linechart .label {
    font-weight:bold;
    font-size:14px;
}

.linechart text.label.y {
    text-anchor: middle; /* align center */
    dominant-baseline: text-after-edge;
}

.linechart .tick.x text, .linechart text.label.x {
    text-anchor: middle; /* align center */
    dominant-baseline: text-before-edge;
}
.linechart .tick.y text {
    text-anchor: end;
    dominant-baseline: middle; /* vertical alignment fix */
}


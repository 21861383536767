
.catChart {
    width: max(350px, 80%);
}

header .right {
    float: right;
    padding-right:clamp(10px, 5vw, 80px);
}

.right h1 {
    margin-block-end: 0.2em;
}

.telegram {
    color:var(--c7);
}

main {
    display: grid;
    place-items: center;
}

.latest {
    font-weight:bold;
    padding:1rem 0.5rem;
}
.parent {
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.logo {
    width:80px;
    float:left; 
    padding-top:10px;
    padding-left: 10px;
}

.logo.loading {
    animation: spin 1.2s linear infinite
}

.logo.nc {
    transform: rotate(180deg);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

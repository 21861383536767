@import url('https://fonts.googleapis.com/css?family=Jura');
/*@import url('https://fonts.googleapis.com/css?family=Raleway:400,300,600');*/

:root {
    --c1:#121214;
    --c2:#291f1e;
    --c3:#634133;
    --c4:#e52919;
    --c5:#f4d58d;
    --c6:#eee;
    --c7:#e5e5e5;
    --font-family-base: 'Jura', sans-serif;

    --alt-color: #0fa0ce;
    --brand-color: #1eaedb;
    --link-visited: var(--alt-color);
    --link: #8f7db8
}

body {
    background-color: var(--c2);
    color: var(--c6);
    font-family: var(--font-family-base)
}

a:link{
    color: var(--link);
}
a:visited{
    color: var(--link-visited);
}

